import React, { useContext, useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Router } from '@reach/router'
import { ProfileContext } from '../context/ProfileContext'
import { navigate, useIntl } from 'gatsby-plugin-intl'
import _, { orderBy } from 'lodash'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ToggleLevel from '../components/profile/ToggleLevel'
import ToggleStats from '../components/profile/ToggleStats'
import { AnimatedTabs, AnimatedTab } from '../components/AnimatedTabs'
import { TabList, TabPanels, TabPanel } from '@reach/tabs'
import HeroDisplay from '../components/HeroDisplay'
import ElementStats from '../components/profile/ElementStats'
import LimitBreakStats from '../components/profile/LimitBreakStats'
import ReactTooltip from 'react-tooltip'
import styles from '../styles/index.style'
import { getClassName } from '../components/profile/StatsBar'

// import styled from 'styled-components'

const ProfileComponent = ({ data, id }) => {
  const intl = useIntl()
  const { profile, setProfile, setStorageProfile, getDefaultHeroes, resetProfile } = useContext(ProfileContext)

  const [heroes, setHeroes] = useState()
  const [profileName, setProfileName] = useState('')
  const [profileMessage, setProfileMessage] = useState('')
  const [toggleLevel, setToggleLevel] = useState(true)
  const [toggleStats, setToggleStats] = useState(true)
  const tooltipRef = useRef()

  const setProfileHeroes = (result) => {
    let defaultHeroes = getDefaultHeroes(intl.locale)
    result.map((e) => {
      // defaultHeroes.merge(e.heroes)
      // const heroes = _.merge(_.keyBy(defaultHeroes, 'id'), _.keyBy(e.heroes, 'id'))
      setProfileName(e.profile_name)
      setProfileMessage(e.profile_message)
      setHeroes(
        orderBy(
          _.merge(_.keyBy(defaultHeroes, 'id'), _.keyBy(e.heroes, 'id')),
          ['evolution', 'guardianPoints', 'releaseDate'],
          ['desc', 'desc', 'desc']
        )
      )
      // defaultHeroes.map(d=>e.heroes.in)
      // console.log(_.unionBy(e.heroes, defaultHeroes, (e) => e.id))
      // setHeroes(e.heroes.unionBy(defaultHeroes, (e) => e.id))
    })
  }

  useEffect(() => {
    const fetchProfile = async () => {
      let translator = require('short-uuid')()
      let uuid = translator.toUUID(id)
      fetch(`/.netlify/functions/getProfile?uuid=${uuid}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.length) setProfileHeroes(data)
          else {
            // if (error) console.log('error', error)
            navigate('/')
          }
        })
      // let { data: result } = await supabase
      //   .from('Profiles')
      //   .select(`profile_name, profile_message, heroes`)
      //   .eq('uuid', uuid)
      //   .limit(1)
      // if (result.length) setProfileHeroes(result)
      // else {
      //   // if (error) console.log('error', error)
      //   navigate('/')
      // }
    }
    fetchProfile().catch(console.error)
  }, [])

  let elementStats = []
  let elementComponent = data.elements.edges.map(({ node }) => ({
    id: node.contentful_id,
    collectedCount: 0,
  }))
  let heroesLimitBreakStats = Array(6)
    .fill()
    .map((_, i) => ({
      limitBreak: i,
      stats: [...elementComponent],
    }))
    .reverse()
  let weaponLimitBreakStats = Array(6)
    .fill()
    .map((_, i) => ({
      limitBreak: i,
      stats: [...elementComponent],
    }))
    .reverse()
  let profileUrl = window.location.href
  let fullProfileName = intl.formatMessage(
    { id: profileName ? 'someone_profile' : 'my_profile' },
    {
      someone: profileName,
    }
  )

  return (
    <div className="w-full lg:max-w-screen-lg mx-auto px-4">
      <SEO title={fullProfileName} description={profileMessage} />
      <div className="max-w-screen-sm mx-auto my-4 mb-6 sm:my-6 relative text-gray-700 z-0">
        <div className="absolute inset-y-0 left-0 px-2 flex items-center pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
            />
          </svg>
        </div>
        <a ref={tooltipRef} data-tip="profile-url-copied">
          <input
            readOnly
            className="w-full h-10 px-10 text-base placeholder-gray-600 border rounded-lg outline-none"
            type="text"
            value={profileUrl}
            onFocus={(event) => {
              event.target.select()
            }}
          />
          <button className="absolute inset-y-0 right-0 flex items-center px-4 rounded-r-lg bg-gray-100 hover:bg-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
        </a>
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          event="click"
          afterShow={() => {
            navigator.clipboard?.writeText(profileUrl)
            setTimeout(ReactTooltip.hide, 1500)
          }}
        >
          {intl.formatMessage({ id: 'profile_link_copied' })}
        </ReactTooltip>
      </div>
      <h1 className="text-2xl md:text-3xl text-white text-center">{fullProfileName}</h1>
      <ToggleLevel toggleLevel={toggleLevel} setToggleLevel={setToggleLevel} />
      <ToggleStats toggleStats={toggleStats} setToggleStats={setToggleStats} />
      <AnimatedTabs>
        <TabList className="flex flex-row flex-wrap list-none items-stretch place-items-stretch">
          <AnimatedTab index={0}>{intl.formatMessage({ id: 'by_elements' })}</AnimatedTab>
          <AnimatedTab index={1}>{intl.formatMessage({ id: 'by_hero_limit_break' })} </AnimatedTab>
          <AnimatedTab index={2}>{intl.formatMessage({ id: 'by_weapon_limit_break' })} </AnimatedTab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <table className={`w-full mt-2 ${toggleStats ? 'lg:max-w-screen-lg' : 'lg:max-w-screen-md'} mx-auto`}>
              <tbody>
                {data.elements.edges.map(({ node }, i) => {
                  elementStats.push({
                    id: i,
                    collectedHeroes: 0,
                    totalHeroes: 0,
                    collectedWeapons: 0,
                    totalWeapons: 0,
                    totalGuardianPoints: 0,
                  })
                  return (
                    <tr key={i}>
                      <td className={`${toggleStats ? 'hidden lg:table-cell' : ''} w-12 lg:w-16 content-center`}>
                        <GatsbyImage
                          alt={node.name}
                          className="w-10 lg:w-12 mx-auto"
                          image={node.icon.gatsbyImageData}
                          placeholder="blurred"
                        />
                        {/* <img className="w-10 lg:w-12 mx-auto" src={node.icon.fixed.src} alt={node.name} /> */}
                      </td>
                      <td>
                        <div className="flex flex-row flex-wrap items-center">
                          {/* <div className="flex-1 grid grid-cols-6"> */}
                          <div className={styles.heroDisplayGrid}>
                            {/* <td className="flex flex-row flex-wrap"> */}
                            {heroes
                              ?.filter((e) => e.element.id === node.contentful_id)
                              .map((e) => {
                                let record = elementStats[i]
                                _.assign(record, {
                                  ...record,
                                  collectedHeroes:
                                    e.evolution > -1 ? record.collectedHeroes + 1 : record.collectedHeroes,
                                  totalHeroes: record.totalHeroes + 1,
                                  collectedWeapons:
                                    e.weaponLimitBreak > -1 ? record.collectedWeapons + 1 : record.collectedWeapons,
                                  totalWeapons: record.totalWeapons + 1,
                                  totalGuardianPoints: record.totalGuardianPoints + e.guardianPoints,
                                })
                                return <HeroDisplay hero={e} key={e.id} toggleLevel={toggleLevel} />
                              })}
                          </div>
                          {heroes && toggleStats ? (
                            <ElementStats elementIndex={i} elementStats={elementStats[i]} />
                          ) : null}
                        </div>
                        <div className={styles.underline(getClassName(i, 'from'))} style={{ height: '3px' }} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className={`w-full mt-2 ${toggleStats ? 'lg:max-w-screen-lg' : 'lg:max-w-screen-md'} mx-auto`}>
              <tbody>
                {heroes
                  ? heroesLimitBreakStats.map((e) => {
                      let matchedHeroes = heroes.filter((f) => f.heroLimitBreak === e.limitBreak && f.evolution > -1)
                      if (matchedHeroes.length > 0)
                        return (
                          <tr key={e.limitBreak}>
                            <td className="w-12 lg:w-16 content-center text-2xl font-black text-white">
                              {'+' + e.limitBreak}
                            </td>
                            <td>
                              <div className="flex flex-row flex-wrap items-center">
                                <div className={styles.heroDisplayGrid}>
                                  {matchedHeroes.map((g) => {
                                    let statIndex = e.stats.findIndex((h) => h.id === g.element.id)
                                    e.stats[statIndex] = {
                                      id: e.stats[statIndex].id,
                                      collectedCount: e.stats[statIndex].collectedCount + 1,
                                    }
                                    return <HeroDisplay hero={g} key={g.id} toggleLevel={toggleLevel} />
                                  })}
                                </div>
                                {heroes && toggleStats ? (
                                  <LimitBreakStats nodes={data.elements.edges} stats={e.stats} />
                                ) : null}
                              </div>
                              <div className={styles.underline('from-gray-500')} style={{ height: '3px' }} />
                            </td>
                          </tr>
                        )
                    })
                  : null}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className={`w-full mt-2 ${toggleStats ? 'lg:max-w-screen-lg' : 'lg:max-w-screen-md'} mx-auto`}>
              <tbody>
                {heroes
                  ? weaponLimitBreakStats.map((e) => {
                      let matchedHeroes = heroes.filter((f) => f.weaponLimitBreak === e.limitBreak)
                      if (matchedHeroes.length > 0)
                        return (
                          <tr key={e.limitBreak}>
                            <td className="w-12 lg:w-16 content-center text-2xl font-black text-white">
                              {'+' + e.limitBreak}
                            </td>
                            <td>
                              <div className="flex flex-row flex-wrap items-center">
                                <div className={styles.heroDisplayGrid}>
                                  {matchedHeroes.map((g) => {
                                    let statIndex = e.stats.findIndex((h) => h.id === g.element.id)
                                    e.stats[statIndex] = {
                                      id: e.stats[statIndex].id,
                                      collectedCount: e.stats[statIndex].collectedCount + 1,
                                    }
                                    return <HeroDisplay hero={g} key={g.id} toggleLevel={toggleLevel} />
                                  })}
                                </div>
                                {heroes && toggleStats ? (
                                  <LimitBreakStats nodes={data.elements.edges} stats={e.stats} />
                                ) : null}
                              </div>
                              <div className={styles.underline('from-gray-500')} style={{ height: '3px' }} />
                            </td>
                          </tr>
                        )
                    })
                  : null}
              </tbody>
            </table>
          </TabPanel>
        </TabPanels>
      </AnimatedTabs>
      {profileMessage ? (
        <div className="w-full md:w-1/2 mx-auto pt-2">
          <h3 className="mb-2 text-xl font-medium leading-6 text-white">
            {intl.formatMessage({ id: 'profile_message' })}
          </h3>
          <textarea
            readOnly
            className="w-full rounded-lg border-gray-300 shadow-sm p-2"
            rows={3}
            value={profileMessage}
          />
        </div>
      ) : null}
      <div className="flex w-full md:w-1/2 justify-evenly pt-4 pb-6 mx-auto">
        <button
          type="button"
          onClick={() => {
            let clonedProfile = {
              ...profile,
              myHeroes: orderBy(heroes, ['element.order'], ['asc']),
              hasModified: true,
              totalGuardianPoints: _.sumBy(heroes, (e) => e.guardianPoints),
            }
            setProfile(clonedProfile)
            setStorageProfile(clonedProfile)
            navigate('/')
          }}
          className="py-2 px-4 bg-yellow-button border-yellow-border border-2 text-black font-bold rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none"
        >
          {intl.formatMessage({ id: 'copy_profile' })}
        </button>
        <button
          type="button"
          onClick={() => {
            resetProfile(intl.locale)
            navigate('/')
          }}
          className={styles.shareButton(true)}
        >
          {intl.formatMessage({ id: 'new_profile' })}
        </button>
      </div>
    </div>
  )
}

// const StyledBlock = styled.td`
//   &:after {
//     content: '';
//     left: 0;
//     bottom: -7px;
//     height: 4px;
//     background-color: #fff;
//     width: 100%;
//   }
// `

const Profile = ({ data, pageContext }) => {
  return (
    <Layout>
      <Router>
        {/* default path */}
        <ProfileComponent data={data} path="profile/:id" language={pageContext.language} />
        {/* path for locale */}
        <ProfileComponent data={data} path={pageContext.language + '/profile/:id'} language={pageContext.language} />
      </Router>
    </Layout>
  )
}

export default Profile

export const query = graphql`
  query ProfileQuery($language: String) {
    elements: allContentfulElements(filter: { node_locale: { eq: $language } }, sort: { fields: order }) {
      edges {
        node {
          contentful_id
          name
          icon {
            gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 110)
          }
        }
      }
    }
  }
`
