import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import ReactTooltip from 'react-tooltip'
// import EvolutionStar from './EvolutionStar'
import styles from '../styles/index.style'
import { getMaxEvoStage } from '../context/useProfile'
// import { Textfit } from 'react-textfit'

const HeroDisplay = ({ hero, toggleLevel }) => {
  const intl = useIntl()

  let hasHero = hero?.evolution > -1
  let hasHeroMaxEvolved = hero?.evolution === getMaxEvoStage(hero)
  let hasWeapon = hero?.weaponLimitBreak > -1

  return (
    <a data-tip data-for={hero.id}>
      <div className={styles.heroDisplay(hasHero)}>
        <div className="absolute">
          <GatsbyImage
            alt={hero.name}
            className={styles.heroImage(hasHero)}
            image={hero.gatsbyImageData}
            placeholder="blurred"
            imgStyle={{ borderRadius: '0.75rem' }}
          />
          <ReactTooltip id={hero.id} place="bottom" effect="solid">
            <span>{hero.name}</span>
          </ReactTooltip>
        </div>
        {/* <img className={styles.heroImage(hasHero)} src={hero.img} /> */}
        {hasHero || hasWeapon ? (
          <div
            className={styles.displayLayer()}
            style={{
              background: 'linear-gradient(0deg, black, transparent 25%, transparent 90%, rgba(0,0,0,.5))',
            }}
          >
            {hasHero && hasHeroMaxEvolved ? (
              <div className={styles.limitBreakText(true)} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
                <div className="w-2/5 text-center">
                  {/* <Textfit mode="single" forceSingleModeWidth={false} min={1}> */}
                  {toggleLevel ? 87 + hero.heroLimitBreak : '+' + hero.heroLimitBreak}
                  {/* {'+' + hero.heroLimitBreak} */}
                  {/* {78 + hero.heroLimitBreak} */}
                  {/* </Textfit> */}
                </div>
                <StaticImage
                  className="w-2/5"
                  src="../images/head_1.png"
                  alt={intl.formatMessage({ id: 'hero_limit_break' })}
                  placeholder="blurred"
                />
              </div>
            ) : null}
            {hasWeapon ? (
              <div className={styles.limitBreakText(false)} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
                <div className="w-2/5 text-center">
                  {/* <Textfit mode="single" forceSingleModeWidth={false} min={1}> */}
                  {toggleLevel ? 87 + hero.weaponLimitBreak : '+' + hero.weaponLimitBreak}
                  {/* {'+' + hero.weaponLimitBreak} */}
                  {/* {78 + hero.weaponLimitBreak} */}
                  {/* </Textfit> */}
                </div>
                <StaticImage
                  className="w-2/5"
                  src="../images/weapon_1.png"
                  alt={intl.formatMessage({ id: 'weapon_limit_break' })}
                  placeholder="blurred"
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {hasHero ? (
          <div className="select-none">
            <div className={styles.heroEvolutionText} style={{ textShadow: '0.1em 0.1em 0.1em black' }}>
              {hero.evolution + (5 - getMaxEvoStage(hero))}
            </div>
            {/* <EvolutionStar className="absolute top-1 left-3 xl:-ml-1 xl:-mt-0.5" /> */}
            <StaticImage
              className="absolute top-0 left-3 xl:-ml-1 mt-0.5 md:mt-1 lg:mt-0.5 w-4 h-4"
              src="../images/star.png"
              alt={intl.formatMessage({ id: 'hero_evolution' })}
              placeholder="blurred"
            />
          </div>
        ) : null}
        {hasWeapon ? (
          <div className="h-0">
            <StaticImage
              className={styles.weaponFlag}
              src="../images/flag.png"
              alt={intl.formatMessage({ id: 'own_exclusive_weapon' })}
              style={{ position: 'absolute', marginTop: '-6%', marginRight: '-2%' }}
            />
          </div>
        ) : null}
      </div>
    </a>
  )
}

export default HeroDisplay
