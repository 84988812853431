import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const LimitBreakStats = ({ nodes, stats }) => (
  <div className="flex-none w-full lg:w-60 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 justify-center px-4">
    {nodes.map((e) => (
      <div className="w-full inline-flex items-center p-1" key={e.node.contentful_id}>
        <GatsbyImage
          alt={e.node.name}
          className="w-8 lg:w-1/2"
          image={e.node.icon.gatsbyImageData}
          placeholder="blurred"
        />
        {/* <img className="w-8 lg:w-1/2" src={e.node.icon.fixed.src} alt={e.node.name} /> */}
        <div className="w-1/2 content-center text-xl md:text-2xl font-black text-white text-center">
          {stats.find((f) => f.id === e.node.contentful_id).collectedCount}
        </div>
      </div>
    ))}
  </div>
)

export default LimitBreakStats
