import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Switch } from '@headlessui/react'

const ToggleLevel = ({ toggleLevel, setToggleLevel }) => {
  const intl = useIntl()

  return (
    <div className="flex my-2">
      <div className="ml-auto">
        <Switch.Group>
          <Switch.Label className="text-white text-base lg:text-lg p-2">
            {intl.formatMessage({ id: 'show_max_level' })}
          </Switch.Label>
          <Switch
            checked={toggleLevel}
            onChange={setToggleLevel}
            className={`select-none transform transition ease-in-out duration-300 ${
              toggleLevel ? 'bg-yellow-button' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11`}
          >
            <span
              className={`${
                toggleLevel ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 p-1 bg-white rounded-full shadow-md transform duration-300`}
            />
          </Switch>
        </Switch.Group>
      </div>
    </div>
  )
}

export default ToggleLevel
