import React from 'react'
import StatsBar from './StatsBar'

const ElementStats = ({ elementIndex, elementStats }) => (
  <div className="flex-none w-full lg:w-60 flex flex-col flex-wrap justify-center">
    <StatsBar
      elementIndex={elementIndex}
      type="hero"
      percentage={elementStats.collectedHeroes / elementStats.totalHeroes}
      text={elementStats.collectedHeroes + '/' + elementStats.totalHeroes}
    />

    <StatsBar
      elementIndex={elementIndex}
      type="weapon"
      percentage={elementStats.collectedWeapons / elementStats.totalWeapons}
      text={elementStats.collectedWeapons + '/' + elementStats.totalWeapons}
    />
    <StatsBar
      elementIndex={elementIndex}
      type="guardian_points"
      percentage={getGuardianPointsPercentage(elementStats)}
      text={
        elementStats.totalGuardianPoints.toLocaleString() +
        ' (' +
        (getGuardianPointsPercentage(elementStats) * 100).toPrecision(2) +
        '%)'
      }
    />
  </div>
)

const getGuardianPointsPercentage = (stats) => stats.totalGuardianPoints / (stats.totalHeroes * 1320)

export default ElementStats
