import React, { useEffect, useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import ReactTooltip from 'react-tooltip'
import { StaticImage } from 'gatsby-plugin-image'
import profileStyles from '../../styles/profile.styles'

const StatsBar = ({ elementIndex, type, text, percentage }) => {
  const intl = useIntl()
  const [animate, setAnimated] = useState(false)

  useEffect(() => {
    setAnimated(true)
  }, [])

  let resource
  switch (type) {
    case 'hero':
      resource = {
        desc: intl.formatMessage({ id: 'hero_completeness' }),
      }
      break
    case 'weapon':
      resource = {
        desc: intl.formatMessage({ id: 'weapon_completeness' }),
      }
      break
    case 'guardian_points':
      resource = {
        desc: intl.formatMessage({ id: 'total_guardian_points' }),
      }
      break
  }

  return (
    <>
      <a data-tip data-for={type}>
        <div className="w-full h-10 inline-flex items-center">
          <div className="flex-1">
            {type === 'hero' ? (
              <StaticImage
                quality={100}
                className={profileStyles.statsIconStyle(getClassName(elementIndex, 'bg'))}
                src="../../images/head_0.png"
                alt={resource.desc}
              />
            ) : null}
            {type === 'weapon' ? (
              <StaticImage
                quality={100}
                className={profileStyles.statsIconStyle(getClassName(elementIndex, 'bg'))}
                src="../../images/weapon_0.png"
                alt={resource.desc}
              />
            ) : null}
            {type === 'guardian_points' ? (
              <StaticImage quality={100} className={'w-8 h-8 z-40'} src="../../images/point.png" alt={resource.desc} />
            ) : null}
          </div>
          <div className={profileStyles.colorBarDiv}>
            <div
              className={profileStyles.colorBarFilling(getClassName(elementIndex, 'bg'), getOpacity(percentage))}
              style={animate ? finishStyle(intl.formatNumber(percentage, { style: 'percent' })) : initialStyle}
            />
            <div
              className={profileStyles.colorBarBorder(getClassName(elementIndex, 'border'))}
              style={{ width: '100%' }}
            />
            <div className="absolute inset-0 -ml-12 -mt-0.5 text-white text-center font-bold">{text}</div>
          </div>
        </div>
      </a>
      <ReactTooltip id={type} place="left" effect="solid">
        <span>{resource.desc}</span>
      </ReactTooltip>
    </>
  )
}

const finishStyle = (percentage) => ({
  width: percentage,
  borderTopRightRadius: percentage === '100%' ? '0.5rem' : 0,
  borderBottomRightRadius: percentage === '100%' ? '0.5rem' : 0,
  transition: 'width 1s, border-top-right-radius 1s, border-bottom-right-radius 1s, ease-out 1s',
})

const initialStyle = {
  width: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
}

const getOpacity = (percentage) => {
  if (percentage === 1) return 'bg-opacity-70'
  else if (percentage > 0.5) return 'bg-opacity-50'
  else return 'bg-opacity-30'
}

export const getClassName = (index, type) => {
  switch (index) {
    case 0:
      return type + '-fire'
    case 1:
      return type + '-water'
    case 2:
      return type + '-earth'
    case 3:
      return type + '-light'
    case 4:
      return type + '-dark'
    case 5:
      return type + '-basic'
  }
}

export default StatsBar
