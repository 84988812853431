import React, { createContext, useContext, useLayoutEffect, useRef, useState } from 'react'
import { useRect } from '@reach/rect'
import { Tabs, Tab, useTabsContext } from '@reach/tabs'

const AnimatedContext = createContext()

export const AnimatedTabs = ({ children, ...rest }) => {
  // some state to store the position we want to animate to
  const [activeRect, setActiveRect] = useState(null)
  const ref = useRef()
  const rect = useRect(ref)

  return (
    // put the function to change the styles on context so an active Tab
    // can call it, then style it up
    <AnimatedContext.Provider value={setActiveRect}>
      {/* make sure to forward props since we're wrapping Tabs */}
      <Tabs className="relative" {...rest} ref={ref} style={{ ...rest.style }}>
        {/* <div
          style={{
            position: 'absolute',
            height: 4,
            background: '#D1D5DB',
            transition: 'all 500ms ease',
            left: (activeRect && activeRect.left) - (rect && rect.left),
            top: (activeRect && activeRect.bottom) - (rect && rect.top) - 4,
            // subtract both sides of horizontal padding to center the div
            width: activeRect && activeRect.width,
          }}
        /> */}
        {children}
      </Tabs>
    </AnimatedContext.Provider>
  )
}

export const AnimatedTab = ({ index, ...props }) => {
  // get the currently selected index from useTabsContext
  const { selectedIndex } = useTabsContext()
  const isSelected = selectedIndex === index

  // measure the size of our element, only listen to rect if active
  const ref = useRef()
  const rect = useRect(ref, { observe: isSelected })

  // get the style changing function from context
  const setActiveRect = useContext(AnimatedContext)

  // call up to set styles whenever we're active
  useLayoutEffect(() => {
    if (isSelected) {
      setActiveRect(rect)
    }
  }, [isSelected, rect, setActiveRect])

  return (
    <Tab
      className={
        'flex-1 mx-1 py-3 text-xs sm:text-base text-center rounded-t-lg shadow-xl bg-opacity-80 border-b-4 ' +
        (isSelected ? 'font-bold bg-midnight border-gray-300' : 'bg-white border-darkblue')
      }
      ref={ref}
      {...props}
      style={{
        ...props.style,
        color: isSelected ? 'white' : '#414B6E',
      }}
    />
  )
}
