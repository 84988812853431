export default {
  statsIconStyle: (color) =>
    [
      'w-8',
      'h-8',
      'z-40',
      'rounded-full',
      color,
      // 'sm:to-transparent',
      // hasHero ? 'border-skyblue' : 'border-gray-300',
      // 'border-2',
      // 'rounded-xl',
      // 'shadow-md',
    ].join(' '),
  colorBarDiv: ['flex-0', 'relative', 'w-full', 'h-3/5', 'mb-1'].join(' '),
  colorBarBorder: (color) =>
    [
      'absolute',
      'inset-0',
      '-ml-4',
      'border-2',
      'border-l-0',
      'rounded-r-lg',
      'pl-8',
      color,
      // 'sm:to-transparent',
      // hasHero ? 'border-skyblue' : 'border-gray-300',
      // 'border-2',
      // 'rounded-xl',
      // 'shadow-md',
    ].join(' '),
  colorBarFilling: (color, opacity) =>
    [
      'absolute',
      'inset-0',
      '-ml-4',
      //   opacity === 'bg-opacity-70' ? 'rounded-r-lg' : null,
      color,
      opacity,
      // 'sm:to-transparent',
      // hasHero ? 'border-skyblue' : 'border-gray-300',
      // 'border-2',
      // 'rounded-xl',
      // 'shadow-md',
    ].join(' '),
}
